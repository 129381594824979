import React from "react";
import {
  Grid,
  TextField,
  FormLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import Card from "../muiFacade/Card";
import CardHeader from "../muiFacade/CardHeader";
import CardContent from "../muiFacade/CardContent";

import {
  PENDING,
  REVIEW,
  INPROGRESS,
  REJECTED,
  ENDED,
} from "../../utils/status";

const LocationCard = ({
  handleSelect,
  data,
  location,
  projects,
  channels,
  structures,
  fisCoor,
  comCoor,
  locationChange,
  actionVisit,
  setRejected,
  handleSaveVisit,
}) => {
  const status = data.statusId;
  const buildChannelSelect = () => {
    let items = [
      <MenuItem key={-1} value={0}>
        Selecciona una opción
      </MenuItem>,
    ];
    channels.forEach((d, index) => {
      items.push(
        <MenuItem key={index} value={d.channelId}>
          {d.channel}
        </MenuItem>
      );
    });
    return items;
  };

  const buildStructureSelect = () => {
    let items = [
      <MenuItem key={-1} value={0}>
        Selecciona una opción
      </MenuItem>,
    ];
    structures.forEach((d, index) => {
      items.push(
        <MenuItem key={index} value={d.structureId}>
          {d.structure}
        </MenuItem>
      );
    });
    return items;
  };

  const buildProjectSelect = () => {
    let items = [
      <MenuItem key={-1} value={0}>
        Selecciona una opción
      </MenuItem>,
    ];
    projects.forEach((d, index) => {
      items.push(
        <MenuItem key={index} value={d.projectId}>
          {d.project}
        </MenuItem>
      );
    });
    return items;
  };

  const isSaveDisabled =
    status === INPROGRESS ||
    status === REJECTED ||
    status === ENDED ||
    !!actionVisit ||
    !data.visitDate ||
    !data.programDate;

  return (
    <Card>
      <CardHeader title={"Ubicación"} />
      <CardContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={7}>
            <Grid
              container={true}
              spacing={1}
              xs={12}
              sm={12}
              justifyContent="start"
              alignItems="center"
            >
              <Grid
                item={true}
                xs={12}
                sm={6}
                container
                justifyContent="start"
                alignItems="center"
              >
                <FormLabel>Proyecto</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Select
                  name={"project"}
                  value={location.project}
                  onChange={handleSelect}
                  fullWidth={true}
                >
                  {buildProjectSelect()}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <Grid
              container={true}
              spacing={1}
              justifyContent="start"
              alignItems="center"
              xs={12}
              sm={12}
            >
              <Grid
                item={true}
                xs={12}
                sm={12}
                justifyContent="start"
                container
              >
                <FormLabel>Dirección</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Select
                  name={"address"}
                  value={location.address}
                  fullWidth={true}
                  onChange={handleSelect}
                >
                  <MenuItem value={1}>Comercial</MenuItem>
                  <MenuItem value={2}>Fiscal</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={7}>
            <Grid
              container={true}
              spacing={1}
              xs={12}
              sm={12}
              justifyContent="start"
              alignItems="center"
            >
              <Grid
                item={true}
                xs={12}
                sm={12}
                container
                justifyContent="start"
              >
                <FormLabel>Ubicación</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"name"}
                  value={location.name}
                  fullWidth={true}
                  onChange={handleSelect}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <Grid
              container={true}
              spacing={1}
              justifyContent="start"
              alignItems="center"
              xs={12}
              sm={12}
            >
              <Grid
                item={true}
                xs={12}
                sm={12}
                container
                justifyContent="flex-start"
              >
                <FormLabel>Clave</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"key"}
                  value={location.key}
                  fullWidth={true}
                  onChange={handleSelect}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={7}>
            <Grid
             container={true}
             spacing={1}
             xs={12}
             sm={12}
             justifyContent="start"
             alignItems="center"
            >
              <Grid
                item={true}
                xs={12}
                sm={6}
                container
                justifyContent="start"
                alignItems="center"
              >
                <FormLabel>Estructura</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Select
                  name={"structure"}
                  value={location.structure}
                  onChange={handleSelect}
                  fullWidth={true}
                >
                  {buildStructureSelect()}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <Grid
              container={true}
              spacing={1}
              justifyContent="start"
              alignItems="center"
            >
              <Grid
                item={true}
                xs={12}
                sm={6}
                container
                justifyContent="start"
                alignItems="center"
              >
                <FormLabel>Canal</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <Select
                  name={"channel"}
                  value={location.channel}
                  fullWidth={true}
                  onChange={handleSelect}
                >
                  {buildChannelSelect()}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={7}>
            <Grid
             container={true}
             spacing={1}
             xs={12}
             sm={12}
             justifyContent="start"
             alignItems="center"
            >
              <Grid
               item={true}
               xs={12}
               sm={6}
               container
               justifyContent="start"
               alignItems="center"
              >
                <FormLabel>Estado</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"state"}
                  disabled={true}
                  fullWidth={true}
                  value={
                    location.address === 1
                      ? data.commercialAddress.state || ""
                      : data.fiscalAddress.state || ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <Grid
               container={true}
               spacing={1}
               justifyContent="start"
               alignItems="center"
            >
              <Grid
                 item={true}
                 xs={12}
                 sm={6}
                 container
                 justifyContent="start"
                 alignItems="center"
              >
                <FormLabel>Municipio</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"municipality"}
                  disabled={true}
                  fullWidth={true}
                  value={
                    location.address === 1
                      ? data.commercialAddress.municipality || ""
                      : data.fiscalAddress.municipality || ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={7}>
            <Grid
             container={true}
             spacing={1}
             xs={12}
             sm={12}
             justifyContent="start"
             alignItems="center"
            >
              <Grid
                item={true}
                xs={12}
                sm={6}
                container
                justifyContent="start"
                alignItems="center"
              >
                <FormLabel>Colonia</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"state"}
                  disabled={true}
                  fullWidth={true}
                  value={
                    location.address === 1
                      ? data.commercialAddress.neighborhood || ""
                      : data.fiscalAddress.neighborhood || ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <Grid
               container={true}
               spacing={1}
               justifyContent="start"
               alignItems="center"
            >
              <Grid
                item={true}
                xs={12}
                sm={6}
                container
                justifyContent="start"
                alignItems="center"
              >
                <FormLabel>Calle</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"street"}
                  disabled={true}
                  fullWidth={true}
                  value={
                    location.address === 1
                      ? data.commercialAddress
                        ? data.commercialAddress.street +
                          " " +
                          data.commercialAddress.exNum
                        : ""
                      : data.fiscalAddress
                      ? data.fiscalAddress.street +
                        " " +
                        data.fiscalAddress.exNum
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={7}>
            <Grid
            container={true}
            spacing={1}
            xs={12}
            sm={12}
            justifyContent="start"
            alignItems="center"
            >
              <Grid
               item={true}
               xs={12}
               sm={6}
               container
               justifyContent="start"
               alignItems="center"
              >
                <FormLabel>Longitud</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"longitude"}
                  onChange={locationChange}
                  fullWidth={true}
                  value={
                    location.address === 1
                      ? data.commercialAddress
                        ? data.commercialAddress.longitude === 0
                          ? comCoor.lng
                          : data.commercialAddress.longitude
                        : ""
                      : data.fiscalAddress
                      ? data.fiscalAddress.longitude === 0
                        ? fisCoor.lng
                        : data.fiscalAddress.longitude
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={5}>
            <Grid
            container={true}
            spacing={1}
            justifyContent="start"
            alignItems="center"
            >
              <Grid
                 item={true}
                 xs={12}
                 sm={6}
                 container
                 justifyContent="start"
                 alignItems="center"
              >
                <FormLabel>Latitud</FormLabel>
              </Grid>
              <Grid item={true} xs={12} sm={12}>
                <TextField
                  name={"latitude"}
                  fullWidth={true}
                  onChange={locationChange}
                  value={
                    location.address === 1
                      ? data.commercialAddress
                        ? data.commercialAddress.latitude === 0
                          ? comCoor.lat
                          : data.commercialAddress.latitude
                        : ""
                      : data.fiscalAddress
                      ? data.fiscalAddress.latitude === 0
                        ? fisCoor.lat
                        : data.fiscalAddress.latitude
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      {/*  ############   ########################*/}
      <Grid container style={{ padding: "20px 8%" }}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              window.history.back();
            }}
          >
            Regresar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setRejected(true)}
                disabled={data.statusId > 2 || actionVisit ? true : false}
              >
                Rechazar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveVisit}
                disabled={isSaveDisabled}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LocationCard;
