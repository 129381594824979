import React, { Component } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
const containerStyle = {
  width: '100%',
  height: '400px' // ajusta según necesites
};

class MapContainer extends Component {
  onMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.props.setLat(lat);
    this.props.setLon(lng);
  };

  render() {
    const { lan, lon } = this.props;
    return (
        <div>
              <LoadScript googleMapsApiKey="AIzaSyAC0IGADj7ia-KnaU8hrCcnBE2m7MJBGNw">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: lan, lng: lon }}
          zoom={15}
        >
          <Marker
            position={{ lat: lan, lng: lon }}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
          />
        </GoogleMap>
      </LoadScript>
        </div>
    

      
    );
  }
}

export default MapContainer;
