import React, { useState, useEffect } from "react";
import moment from "moment";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Spinner from "../component/Spinner";
import ModalError from "../component/ModalError";
import ModalInformative from "../component/ModalInformative";
import ModalRejected from "../component/ModalRejected";

import DetailsCard from "../component/infoCards/DetailsCard";
import AddressCard from "../component/infoCards/AddressCard";
import DatesCard from "../component/infoCards/DatesCard";
import CompanyCard from "../component/infoCards/CompanyCard";
import LocationCard from "../component/infoCards/LocationCard";
import MapCard from "../component/infoCards/MapCard";
import ContactInfoCard from "../component/infoCards/ContactInfoCard";

import {
  getChannels,
  getContactInfo,
  getProjects,
  getStructures,
  saveVisit,
  saveReject,
} from "../service/VisitService";
import { getCoordinates } from "../service/getCoordinates";

function ContactInfo(props) {
  const [spinner, setSpinner] = useState(true);
  const [error, setError] = useState(false);
  const [informative, setInformative] = useState(null);

  const [projects, setProjects] = useState([]);
  const [structures, setStructures] = useState([]);
  const [channels, setChannels] = useState([]);

  const serviceId = props.location.state.serviceId;
  const visitFile = props.location.state.visitFile;
  const [data, setData] = React.useState({});
  const [rejected, setRejected] = useState(null);
  const [reject, setReject] = useState({
    imputable: -1,
    motive: -1,
    imputableDesc: "",
    motiveDesc: "",
    description: "",
  });
  const [actionVisit, setActionVisit] = useState(false);

  const [comCoor, setComCoor] = useState({});
  const [fisCoor, setFisCoor] = useState({});
  const [location, setLocation] = useState({
    project: 0,
    structure: 0,
    channel: 0,
    name: "",
    key: "",
    address: 1,
  });

  useEffect(() => {
    const contactInfo = getContactInfo(serviceId);
    const projects = getProjects();
    const structures = getStructures();
    const channels = getChannels();

    Promise.all([contactInfo, projects, structures, channels])
      .then(([contactInfo, projects, structures, channels]) => { 
        const date = moment();
        setData({ ...contactInfo, visitFile });
        setProjects(projects);
        setStructures(structures);
        setChannels(channels);
        getCoordinates(contactInfo).then((res) => {
          setComCoor(res.comCoor);
          setFisCoor(res.fisCoor);
        });
        setLocation((state) => ({
          ...state,
          name:
            contactInfo.ubicacionU !== null
              ? contactInfo.ubicacionU
              : `${contactInfo.company}_${date.format("YYYY")}`,
          key:
            contactInfo.claveUbicacionU !== null
              ? contactInfo.claveUbicacionU
              :`PNC_${date.format("YYMM")}_${visitFile.match(/-([^-]+)$/)[1]}`,
          project:
          contactInfo.idProyectoU =
          contactInfo.idProyectoU !== null
            ? contactInfo.idProyectoU
            : contactInfo.citeType === "Standard"   
                ? projects.find(
                    (it) => it.project === "Necsus_Visitar a un proveedor y valida su domicilio 2025"
                  )?.projectId ?? projects[5].projectId             
              : projects.find(
                  (it) =>
                    it.project === "Visitar a un proveedor valida y acredita sus datos MARZO 2021"
                )?.projectId ?? projects[4].projectId,
          structure:
            contactInfo.estructuraU !== null
              ? contactInfo.estructuraU
              : structures.find((it) => it.structure === "Estructura linko")
                  ?.structureId ?? structures[0].structureId,
          channel:
            contactInfo.canalU !== null
              ? contactInfo.canalU
              : channels.find((it) => it.channel === "Ubicaciones linko")
                  ?.channelId ?? channels[0].channelId,
        }));
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setSpinner(false);
      });
  }, [serviceId]);

  const handleDateChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSelect = (e) => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const pDate = new Date(data.programDate);
    const vDate = new Date(data.visitDate);

    if (pDate.getTime() > vDate.getTime()) {
      const e = { target: { name: "visitDate", value: "" } };
      handleDateChange(e);
    }
  }, [data.programDate, data.visitDate]);

  const handleChangeReject = (e) => {
    let rec = Object.assign({}, reject);
    rec[e.target.name] = e.target.value;
    rec[e.target.name + "Desc"] = e.currentTarget.innerText;
    setReject(rec);
    console.log(reject);
  };

  const locationChange = (e) => {
    let d = { ...data };
    d[location.address === 1 ? "commercialAddress" : "fiscalAddress"][
      e.target.name
    ] = e.target.value;
    setData(d);
  };

  const handleCloseRejected = () => {
    setRejected(null);
  };

  const handleCloseModal = () => {
    setError(false);
    setInformative(null);
    setRejected(null);
  };
  
  const handleOpenReject =()=>{
    setRejected(true);
  }
  const handleSaveReject = () => {
    setSpinner(true);
    saveReject(serviceId, reject)
      .then(() => {
        setInformative("Se ha rechazado la visita");
        setActionVisit(true);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const handleSaveVisit = () => {
    setSpinner(true);
    saveVisit(location, data, serviceId, comCoor, fisCoor)
      .then(() => {
        setInformative("Se ha guardado la visita.");
        setActionVisit(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {!spinner && (
        <Container>
          <DetailsCard data={data} />
          <ContactInfoCard data={data} />
          <DatesCard
            serviceId={serviceId}
            data={data}
            handleDateChange={handleDateChange}
            setError={setError}
            setInformative={setInformative}
            setSpinner={setSpinner}
          />
          <CompanyCard data={data} />
          <AddressCard data={data} />
          <LocationCard
            data={data}
            location={location}
            projects={projects}
            channels={channels}
            structures={structures}
            comCoor={comCoor}
            fisCoor={fisCoor}
            handleSelect={handleSelect}
            locationChange={locationChange}
            actionVisit={actionVisit}
            setRejected={handleOpenReject}
            handleSaveVisit={handleSaveVisit}
          />
          <MapCard
            location={location}
            comCoor={comCoor}
            fisCoor={fisCoor}
            setComCoor={setComCoor}
            setFisCoor={setFisCoor}
          />
        </Container>
      )}
      {informative && (
        <ModalInformative
          open={!!informative}
          text={informative}
          close={handleCloseModal}
        />
      )}
      {rejected && (
        <ModalRejected
          reject={reject}
          handleChange={handleChangeReject}
          handleSaveReject={handleSaveReject}
          close={handleCloseRejected}
        />
      )}
      {error && <ModalError open={error} close={handleCloseModal} />}
      {spinner && <Spinner />}
    </React.Fragment>
  );
}

export default ContactInfo;
