import { createTheme } from "@material-ui/core";


const theme = createTheme({
    palette: {
        primary: {
            main: '#FF995B',
            contrastText: "#FFFFFF",
        }
    }
});


theme.overrides = {
    MuiButton: {
        containedPrimary: {
            padding: "10px 16px",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "19px",
            "&:hover": {
                backgroundColor: "#FF7C1B"
            }
        },
        outlinedPrimary: {
            padding: "10px 16px",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "19px",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&:hover": {
                boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
                backgroundColor: "#FFFFFF"
            }
        }
    },
    MuiTableCell: {
        root: {
            fontSize: '12px',
            lineHeight: '14px',
        },
        body: {
            padding: '0',
            fontWeight: '400',
            color: '#9B9B99'
        },
        head: {
            fontWeight: '700',
        }
    },
    MuiTableRow: {
        root: {
            height: '72px'
        }
    },
    MuiTablePagination: {
        selectRoot: {
            marginTop: '3px'
        }
    },
    MuiFormLabel: {
        root: {
            fontSize: '16px',
            lineHeight: '19px',
            color: '#000000',
            textAlign: 'right',
            fontWeight:500,
        }
    },
    MuiInputBase: {
        root: {
            height: '40px',
        }
    },
    MuiFilledInput: {
        input: {
            padding: "11px 16px"
        }
    }
}











export default theme;